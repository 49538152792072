// Import the edliy_utils
import {
    makeResponsive,
    placeTitle,
    placeImage,
    placeInput,
    placeSlider,
    hoverMe,
    placeRec,
    hiddenPt,
    fixedPt,
    clearInputFields,
    dragMe,
    placeArrow,
    placeGravity,
    placeMiddleText,
    placeLine,
    placePoint,
    placeGlider,
    placeRuler,
    placeLeftText,
    placeRightText,
    placeSliderSwitch,
    placeCircle,
    placeLogo,
    placeShuffle,
    placeTest
} from '../../../common/edliy_utils-fractions';
const Boxes = {
  box1: function () {
	JXG.Options.board.minimizeReflow = 'none';
  JXG.Options.point.showInfoBox=false;
  JXG.Options.point.highlight=false;
  JXG.Options.image.highlight=false;
  JXG.Options.text.highlight=false;
  JXG.Options.text.fixed=true;
  JXG.Options.curve.highlight=false;
  JXG.Options.text.cssDefaultStyle='fontFamily:Oswald;'
  var brd1 = JXG.JSXGraph.initBoard('jxgbox1',{boundingbox: [-3, 11, 9, -1],keepaspectratio: true, axis:false, ticks:true, grid:true, showCopyright:false, showNavigation:false, pan:{enabled:false}, zoom:{enabled:false}});
  //brd1.suspendUpdate();
  makeResponsive(brd1);
  brd1.options.layer['image'] =12;
  brd1.options.layer['circle'] =13;
  brd1.options.layer['chart'] =14;
  brd1.options.layer['sector'] =15;
  brd1.options.layer['line'] =15;
//	brd1.create('text', [6, 11.5, 'Fraction'],{highlight:false, display:'internal', anchorX:'middle', anchorY:'middle', CssStyle:'fontFamily:Oswald;fontWeight:bold',fontSize:function(){return Math.round(32*brd1.canvasWidth/800.)}, fixed:true});
  placeTitle(brd1, 'Fractions of a Pizza', 'Click on a Slice to Pick/Unpick it');
  placeLogo(brd1);
  var reload = placeShuffle(brd1);
  var test = placeTest(brd1);
  //var num =placeInput(brd1, 8, 4.5, 1);
  //var den=placeInput(brd1, 8, 3.5, 4);
  //hoverMe(brd1, num, 'Numerator', 15, 40);
  //hoverMe(brd1, den, 'Denominator', 15, -40);
  //placeLine(brd1, [8, 4], [9,4], 4, 'black');
  //placeText(brd1, 7, 4, 24, 'Fraction =')
  //var dataArr=[()=>num.Value(), ()=>den.Value()-num.Value()];
  var lenArr = [2, 7, 4, 5, 3, 6, 8];
  var fracArr = [1, 3, 3, 2, 2, 4, 5];
  //var charts=[];
  placeImage(brd1, '/assets/pizza.svg', 1, 2, 4,0);//
//var circ = placeCircle(brd1, [3, 4], [5.5,4], 'black');
//circ.setAttribute({strokeWidth:2, strokeColor:'black'});
  var charts =brd1.create('circle', [[3,4], [5, 4]], {visible:true,
  fillOpacity:0.25, dash:1, strokeColor: 'black', strokeWidth:0, fillColor:'white', layer: 2, highlightStrokeColor: 'black'})
//brd1.create('circle', [3,4], [5,4], {strokeColor:'black', dash:1, fillOpacity:0});
/*var charts =brd1.create('chart', [dataArr], {visible:false, highlightOnSector:true, highlightStrokeColor:'black',
         highlightBySize:true, fillOpacity:0.4, strokeWidth:0, chartStyle:'pie',center:[3,4], colorArray: ['yellow','#0F408D'],
         highlightColorArray:['#FFFFFF','#F9DF82','#F7FA7B','#B0D990','#69BF8E'],
    //     labels:[()=>'Bulldog: '+type1.valueOf()+'/'+(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()),
    //     ()=>'Doberman: '+ type2.valueOf()+'/'+(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()),
    //     ()=>'Greyhound: '+ type3.valueOf()+'/'+(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()),
    //     ()=>'Dalmatian: '+ type4.valueOf()+'/'+(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()),
    //     ()=>'Shar-Pei: '+ type5.valueOf()+'/'+(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())],
         label:{display:'internal',CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd1.canvasWidth/800.)}}, strokeColor:'black', radius:2, strokeWidth:2});*/
//charts.label.setAttribute({visible:false2, offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  var k =0;
  //var reload =brd1.create('image', ['/assets/shuffle.svg', [-2.25,3.4],[1.2, 1.2]], {opacity:1, fixed:true});
  var right = placeImage(brd1, '/assets/check.svg', 6.4, 3.4, 1.2,  0);
  var wrong = placeImage(brd1, '/assets/cross.svg', 6.4, 3.4, 1.2,  0);
  right.setAttribute({visible:false});
  wrong.setAttribute({visible:false});
  test.setLabel('Tap to Test Your Answer')
  test.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  test.on('over', function () {this.label.setAttribute({visible:true});});
  test.on('out', function () {this.label.setAttribute({visible:false});});
  test.on('down', function(){let val = test(); if(val==fracArr[k]){right.setAttribute({visible:true})}else{wrong.setAttribute({visible:true})}});
  /////////////////////////////////////////////////////////////////////////////////////////////
  reload.setLabel('Tap to Shuffle')
  reload.label.setAttribute({visible:false, alignX:'middle', offset:[0, -15], CssStyle:'fontFamily:Oswald', fontSize:12});
  reload.on('over', function () {this.label.setAttribute({visible:true});});
  reload.on('out', function () {this.label.setAttribute({visible:false});});
  reload.on('down', function(){right.setAttribute({visible:false}); wrong.setAttribute({visible:false}); k=Math.min(k+1, 5);ini(); fun();});
  //brd1.create('text', [4, 6.5, 'Select slices that make'],{display:'internal', alignX:'right'});
  placeRightText(brd1, 2.5, 7.5, 'Pick');
  placeMiddleText(brd1, 3.0, 7.5, ()=>fracArr[k]+'/'+lenArr[k]);
  placeLeftText(brd1, 3.5, 7.5, 'of the Pizza');
  //ques.setText();
  //placeMiddleText(brd1, 3.0, 0.5, 'Click on a Slice to Select/Deselect it');
  /////////////////////////////////////////////////////////////////////////////////////////////
  //brd1.create('text', [()=>6+2.75*Math.cos(0.5*type1*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2.75*Math.sin(0.5*type1*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>'Bulldog ('+type1.valueOf()+'/'+(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())+')'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd1.canvasWidth/800.)}});
  //brd1.create('text', [()=>6+2.75*Math.cos((type1+0.5*type2)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2.75*Math.sin((type1+0.5*type2)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>'Doberman ('+type2.valueOf()+'/'+(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()) +')' ],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd1.canvasWidth/800.)}});
  //brd1.create('text', [()=>6+2.75*Math.cos((type1+type2+0.5*type3)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2.75*Math.sin((type1+type2+0.5*type3)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>'Grehound ('+type3.valueOf()+'/'+(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())+')'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd1.canvasWidth/800.)}});
  //brd1.create('text', [()=>6+2.75*Math.cos((type1+type2+type3+0.5*type4)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2.75*Math.sin((type1+type2+type3+0.5*type4)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>'Dalmatian ('+type4.valueOf()+'/'+(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())+')'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd1.canvasWidth/800.)}});
  //brd1.create('text', [()=>6+2.75*Math.cos((type1+type2+type3+type4+0.5*type5)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>3+2.75*Math.sin((type1+type2+type3+type4+0.5*type5)*2*Math.PI/(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf())), ()=>'Shar-Pie ('+type5.valueOf()+'/'+(type1.valueOf()+type2.valueOf()+type3.valueOf()+type4.valueOf()+type5.valueOf()) +')'],{display:'internal',anchorX:'middle', fixed:true, CssStyle:'fontFamily:Oswald', fontSize:function(){return Math.round(16*brd1.canvasWidth/800.)}});
//
/////////////////////
var sect=[];
var ini = function(){
brd1.removeObject(sect,true);
}
/////////////////
 var status =[];
 var fun = function(){
 for(let i=0; i<lenArr[k]; i++)
 {
   sect[i] =brd1.create('sector', [[3,4],
    [3+2*Math.cos((i+1)*2*Math.PI/Math.max(lenArr[k],1)), 4+2*Math.sin((i+1)*2*Math.PI/Math.max(lenArr[k], 1))],
    [3+2*Math.cos((i+2)*2*Math.PI/Math.max(lenArr[k],1)), 4+2*Math.sin((i+2)*2*Math.PI/Math.max(lenArr[k], 1))]],
   {strokeColor:'black', strokeWidth:1, fillOpacity:0.});
   status[i] = 0;
   sect[i].setAttribute({dash:2, center:{visible:false}, radiusPoint:{visible:false}, anglePoint:{visible:false}});
 }
};
 var test = function()
 { let total=0;
   for(let i=0; i<lenArr[k]; i++)
   {
     total = total + Math.abs(Math.sin(status[i]*Math.PI/2));
   }
   return total;
 }
ini(); fun();

brd1.on('up', function(evt)
{
  let coords = brd1.getMousePosition(evt);
  for(let i=0; i<lenArr[k]; i++)
  {
    if(sect[i].hasPointSector(coords[0], coords[1])==true)
    { status[i] +=1;
      right.setAttribute({visible:false});
      wrong.setAttribute({visible:false});
      sect[i].setAttribute({fillOpacity:Math.abs(Math.sin(status[i]*Math.PI/2)), fillColor:'OldLace'});
    }
  }
}
);
//brd1.create('text', [6, 6, function(){return coords[0]}]);
 //num.on('down', function(){i=0; ini();fun();brd1.update()});
 //den.on('down', function(){i=0; ini();fun();brd1.update()});
 /////////////////////////////////////////////////////
 /*let magicGraph = document.querySelector("#jxgbox1");
 let inputFields = magicGraph.querySelectorAll("input");
 var onEachPress = function(event) { i=0; ini(); sect=[]; fun();}
    inputFields.forEach(function(field) {
    field.addEventListener('input', onEachPress);});*/
/*  brd1.create('segment', [[3,4], [()=>3+2*Math.cos(2*Math.PI/(den.Value())), ()=>4+2*Math.sin(2*Math.PI/(den.Value()))]],
  {strokeColor:'black', strokeWidth:1, dash:2});
  brd1.create('segment', [[3,4], [()=>3+2*Math.cos(4*Math.PI/(den.Value())), ()=>4+2*Math.sin(4*Math.PI/(den.Value()))]],
  {strokeColor:'black', strokeWidth:1, dash:2});
  brd1.create('segment', [[3,4], [()=>3+2*Math.cos(6*Math.PI/(den.Value())), ()=>4+2*Math.sin(6*Math.PI/(den.Value()))]],
  {strokeColor:'black', strokeWidth:1, dash:2});
  brd1.create('segment', [[3,4], [()=>3+2*Math.cos(8*Math.PI/(den.Value())), ()=>4+2*Math.sin(8*Math.PI/(den.Value()))]],
  {strokeColor:'black', strokeWidth:1, dash:2});
  brd1.create('segment', [[3,4], [()=>3+2*Math.cos(10*Math.PI/(den.Value())), ()=>4+2*Math.sin(10*Math.PI/(den.Value()))]],
  {strokeColor:'black', strokeWidth:1, dash:2});
  brd1.create('segment', [[3,4], [()=>3+2*Math.cos(12*Math.PI/(den.Value())), ()=>4+2*Math.sin(12*Math.PI/(den.Value()))]],
  {strokeColor:'black', strokeWidth:1, dash:2});*/
//
//reload.on('down', function(){ini();type1=Math.round(Math.random()*3)+1; type2=Math.round(Math.random()*4)+1; type3=Math.round(Math.random()*4)+1;type4=Math.round(Math.random()*2)+1;type5=Math.round(Math.random()*3)+1;
//   i=1;j=1;k=1;l=1;m=1;});
//brd1.unsuspendUpdate();
},
}
export default Boxes;
